import Dialog, { type DialogProps } from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
export type BaseModalProps = {
  open: boolean;
  disableDefaultClose?: boolean;
  scroll?: DialogProps['scroll'];
  children: ReactNode;
  onClose?: () => void;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: DialogProps['fullWidth'];
  TransitionComponent?: DialogProps['TransitionComponent'];
  PaperProps?: DialogProps['PaperProps'];
};
export const BaseModal = ({
  open,
  disableDefaultClose = false,
  scroll,
  TransitionComponent,
  children,
  onClose,
  maxWidth,
  fullWidth,
  PaperProps
}: BaseModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={disableDefaultClose ? undefined : onClose} fullScreen={fullScreen} scroll={scroll} TransitionComponent={TransitionComponent} PaperProps={PaperProps} data-sentry-element="Dialog" data-sentry-component="BaseModal" data-sentry-source-file="BaseModal.tsx">
      {children}
    </Dialog>;
};