import { type IconButtonProps as MuiButtonProps, IconButton as MuiIconButton } from '@mui/material';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
export interface IconButtonProps extends MuiButtonProps {
  icon: ReactNode;
  'aria-label': string;
}
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({
  ...props
}, ref) => {
  return <MuiIconButton ref={ref} {...props}>
      {props.icon}
    </MuiIconButton>;
});