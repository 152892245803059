import { Close } from '@mui/icons-material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { type SxProps, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { space } from '../..//constants/space';
import { IconButton } from '../../Buttons/IconButton';
import { zIndices } from '../../constants/zIndices';
import { BaseModal, type BaseModalProps } from '../BaseModal';
const StyledTitle = styled(DialogTitle, {
  shouldForwardProp: prop => prop !== 'hasTitle'
})<{
  hasTitle: boolean;
}>(({
  hasTitle
}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: hasTitle ? 'space-between' : 'end',
  paddingTop: space(1)
}));
const StyledContent = styled(DialogContent, {
  shouldForwardProp: prop => prop !== 'fullScreen'
})<{
  fullScreen: boolean;
}>(({
  fullScreen
}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: fullScreen ? 'start' : 'center',
  minWidth: fullScreen ? 'auto' : 500
}));
const StyledActions = styled(DialogActions)({
  paddingRight: space(3)
});
const gradientStyles = {
  borderTop: '24px solid transparent',
  background: 'linear-gradient(white, white) padding-box, linear-gradient(95.44deg, #4E23FF 5.29%, #6E29FA 42.52%, #9B32F3 94.71%) border-box'
};
export type StandardModalProps = BaseModalProps & {
  title?: ReactNode;
  actions?: ReactNode;
  showDivider?: boolean;
  contentSx?: SxProps;
  gradientBanner?: boolean;
  height?: string | number;
  width?: string | number;
  contentId?: string;
  disableTitle?: boolean;
  disableCloseButton?: boolean;
};
export const StandardModal = ({
  title,
  actions,
  showDivider,
  children,
  onClose,
  maxWidth = 'sm',
  contentSx,
  fullWidth = false,
  gradientBanner = false,
  width,
  height,
  contentId,
  disableTitle = false,
  disableCloseButton = false,
  ...baseModalProps
}: StandardModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();
  return <BaseModal fullWidth={fullWidth} maxWidth={maxWidth} onClose={onClose} PaperProps={{
    style: {
      ...(gradientBanner && !fullScreen && gradientStyles),
      height: fullScreen ? '100%' : height,
      width: width
    }
  }} {...baseModalProps} data-sentry-element="BaseModal" data-sentry-component="StandardModal" data-sentry-source-file="StandardModal.tsx">
      <StyledTitle hasTitle={Boolean(title)} data-sentry-element="StyledTitle" data-sentry-source-file="StandardModal.tsx">
        {!disableTitle && title}
        {!disableCloseButton && Boolean(onClose) && <IconButton icon={<Close />} aria-label={intl.formatMessage({
        id: 'close'
      })} onClick={onClose} sx={{
        color: 'black',
        zIndex: zIndices.modal
      }} />}
      </StyledTitle>
      {showDivider && <Divider />}
      {children && <StyledContent fullScreen={fullScreen} id={contentId} sx={contentSx}>
          {children}
        </StyledContent>}
      {actions && <StyledActions>{actions}</StyledActions>}
    </BaseModal>;
};